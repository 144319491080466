// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "w_sq d_gv d_cs";
export var heroHeaderCenter = "w_gw d_gw d_cs d_dw";
export var heroHeaderRight = "w_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "w_sr d_gr d_cw";
export var heroParagraphCenter = "w_gs d_gs d_cw d_dw";
export var heroParagraphRight = "w_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "w_ss d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "w_st d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "w_sv d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "w_sw d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "w_sx d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "w_sy C_sy";
export var heroExceptionNormal = "w_sz C_sz";
export var heroExceptionLarge = "w_sB C_sB";
export var Title1Small = "w_sC C_sC C_tl C_tm";
export var Title1Normal = "w_sD C_sD C_tl C_tn";
export var Title1Large = "w_sF C_sF C_tl C_tp";
export var BodySmall = "w_sG C_sG C_tl C_tF";
export var BodyNormal = "w_sH C_sH C_tl C_tG";
export var BodyLarge = "w_sJ C_sJ C_tl C_tH";