// extracted by mini-css-extract-plugin
export var campaignContainer = "r_rk d_dW";
export var campaignContainerFull = "r_rl d_dT";
export var wrapper = "r_qh d_w";
export var leftWrapper = "r_rm d_bz d_bN";
export var rightWrapper = "r_rn";
export var subtitle = "r_rp";
export var title = "r_rq";
export var text = "r_qc d_cs";
export var inlineWrapper = "r_rr d_bC d_bG d_w";
export var btnsWrapper = "r_rs r_rr d_bC d_bG d_w d_bJ";
export var priceWrapper = "r_rt r_rr d_bC d_bG d_w d_ch d_bP";
export var priceHeading = "r_rv";
export var disclaimerWrapper = "r_rw d_cD";
export var mediaSlot = "r_rx";
export var listWrapper = "r_ry d_bC d_bP d_w";
export var btnWrapper = "r_d2";
export var imageWrapper = "r_rz d_fg d_Z";
export var SmallSmall = "r_rB C_rB C_tl C_tJ";
export var SmallNormal = "r_rC C_rC C_tl C_tK";
export var SmallLarge = "r_rD C_rD C_tl C_tG";
export var textLeft = "r_dv";
export var textCenter = "r_dw";
export var textRight = "r_dx";
export var alignLeft = "r_qj";
export var alignCenter = "r_bP";
export var alignRight = "r_qk";