// extracted by mini-css-extract-plugin
export var warningBackgroundlight = "h_ng";
export var warningBackgrounddark = "h_nh";
export var warningBackgroundcustom = "h_nj";
export var bottom = "h_gH";
export var ribbon = "h_nk";
export var modal = "h_nl";
export var bottomRight = "h_nm";
export var btnWrapper = "h_d2 d_bD d_bP d_bJ";
export var contentWrapper = "h_nn";
export var container = "h_np";
export var titleWrapper = "h_nq d_bD d_bP d_dw";
export var elementWrapper = "h_nr d_bD d_bP d_dw";
export var cookieButtons = "h_ns d_bz d_bD d_bP d_bJ";
export var btn = "h_nt d_bz d_bD d_bP";
export var link = "h_nv";
export var decline = "h_nw h_nt d_bz d_bD d_bP";
export var hoverStyle = "h_nx";
export var zoom = "h_ny";
export var row = "h_nz";