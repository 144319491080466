// extracted by mini-css-extract-plugin
export var carouselContainer = "K_wt d_w d_H d_bf d_Z";
export var carouselContainerCards = "K_wv K_wt d_w d_H d_bf d_Z";
export var carouselContainerSides = "K_ww d_w d_H d_Z";
export var prevCarouselItem = "K_wx d_w d_H d_0 d_k";
export var prevCarouselItemL = "K_wy K_wx d_w d_H d_0 d_k";
export var moveInFromLeft = "K_wz";
export var prevCarouselItemR = "K_wB K_wx d_w d_H d_0 d_k";
export var moveInFromRight = "K_wC";
export var selectedCarouselItem = "K_wD d_w d_H d_Z d_bf";
export var selectedCarouselItemL = "K_wF K_wD d_w d_H d_Z d_bf";
export var selectedCarouselItemR = "K_wG K_wD d_w d_H d_Z d_bf";
export var nextCarouselItem = "K_wH d_w d_H d_0 d_k";
export var nextCarouselItemL = "K_wJ K_wH d_w d_H d_0 d_k";
export var nextCarouselItemR = "K_wK K_wH d_w d_H d_0 d_k";
export var arrowContainer = "K_wL d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainer = "K_wM K_wL d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainerHidden = "K_wN K_wM K_wL d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var nextArrowContainer = "K_wP K_wL d_H d_0 d_dB d_bl d_4 d_b1";
export var carouselContainerProducts = "K_wQ";
export var nextArrowContainerHidden = "K_wR K_wP K_wL d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var arrow = "K_kG d_0";
export var prevArrow = "K_wS K_kG d_0";
export var nextArrow = "K_wT K_kG d_0";
export var carouselIndicatorContainer = "K_wV d_w d_dw d_bl d_bz d_bD d_bP d_bJ d_0";
export var btnsContainer = "K_wW d_w d_bz d_bF";
export var carouselText = "K_wX d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var carouselTextInactive = "K_wY K_wX d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var indicator = "K_wZ d_b7";
export var carouselIndicator = "K_w0 K_wZ d_b7";
export var carouselIndicatorSelected = "K_w1 K_wZ d_b7";
export var arrowsContainerTopRight = "K_w2 d_0 d_bl d_bC";
export var arrowsContainerBottomLeft = "K_w3 d_0 d_bl d_bC";
export var arrowsContainerSides = "K_w4 d_0 d_bl d_bC";
export var smallArrowsBase = "K_w5 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainer = "K_w6 K_w5 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrev = "K_w7 K_w6 K_w5 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerSmall = "K_w8 K_w5 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrevSmall = "K_w9 K_w8 K_w5 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var icon = "K_p9";
export var iconSmall = "K_xb";
export var multipleWrapper = "K_xc d_bC d_bF d_bf";
export var multipleImage = "K_xd d_bC";
export var sidesPrevContainer = "K_xf K_w6 K_w5 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";
export var sidesNextContainer = "K_xg K_w6 K_w5 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";