// extracted by mini-css-extract-plugin
export var customText = "t_rT d_dv d_cs d_cg";
export var videoIframeStyle = "t_pS d_d5 d_w d_H d_by d_b1 d_R";
export var customImageWrapper = "t_rV d_cs d_cg d_Z";
export var customRow = "t_qb d_bD d_bf";
export var quoteWrapper = "t_gQ d_bC d_bP d_cs d_cg";
export var quoteBar = "t_pX d_H";
export var masonryImageWrapper = "t_p3";
export var title = "t_rq";
export var Title3Small = "t_rW C_rW C_tl C_tt";
export var Title3Normal = "t_rX C_rX C_tl C_tv";
export var Title3Large = "t_rY C_rY C_tl C_tw";